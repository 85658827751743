<template>
  <div class="init">
    <div class="section contact-hero">
      <div class="container">
        <div class="contact-container">
          <div class="small-container center">
            <h2 class="h2 margin">Contactanos</h2>
            <div class="small-text small-heights">
              <p>
                Viceministerio de Educación Superior de Formación Profesional
              </p>
              <p>Dirección General de Formación de Maestros</p>
            </div>
            <div class="main-grids">
              <div class="pricing-details">
                <div class="small-feature margin-top">
                  <img
                    src="https://uploads-ssl.webflow.com/60846fb2ae1ca459eb55cf40/60856aa377a97916cdf181db_check_icon.svg"
                    loading="lazy"
                    width="20"
                    alt="icon-check"
                    class="check-icon-v-2"
                  />
                  <p>Dirección: Avenida Arce Nro. 2147</p>
                </div>
                <div class="small-feature">
                  <img
                    src="https://uploads-ssl.webflow.com/60846fb2ae1ca459eb55cf40/60856aa377a97916cdf181db_check_icon.svg"
                    loading="lazy"
                    width="20"
                    alt="icon-check"
                    class="check-icon-v-2"
                  />
                  <p>Teléfono: (+591 -2) 2442144</p>
                </div>
                <div class="small-feature">
                  <img
                    src="https://uploads-ssl.webflow.com/60846fb2ae1ca459eb55cf40/60856aa377a97916cdf181db_check_icon.svg"
                    loading="lazy"
                    width="20"
                    alt="icon-check"
                    class="check-icon-v-2"
                  />
                  <p>Whatsapp: (+591)71550970 - (+591)71530671</p>
                </div>
                <div class="small-feature">
                  <img
                    src="https://uploads-ssl.webflow.com/60846fb2ae1ca459eb55cf40/60856aa377a97916cdf181db_check_icon.svg"
                    loading="lazy"
                    width="20"
                    alt="icon-check"
                    class="check-icon-v-2"
                  />
                  <p>Casilla de correo: 3116</p>
                </div>
              </div>
            </div>
            <div id="map" style="width: 100%; height: 350px"></div>
            <br />
            <!-- <p>Ministerio de Educación © {{ new Date().getFullYear() }}</p> -->
            <p>La Paz - Bolivia</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Service from "../router/endpoint";
import mapboxgl from "mapbox-gl";
export default {
  name: "home",
  data() {
    return {
      loading: false,
      coordenadas: {
        latitud: -16.5063621942177,
        longitud: -68.12788158859432,
      },
      map: null,
    };
  },
  mounted() {
    mapboxgl.accessToken = Service.getTokenMap();
    this.map = new mapboxgl.Map({
      container: "map",
      //style: "mapbox://styles/mapbox/streets-v11",
      style: "mapbox://styles/dgfm/cl9mzmfnz003915o5bn8ighdf",    //Estilo del mapa.
      center: [this.coordenadas.longitud, this.coordenadas.latitud],
      zoom: 17,
    });
    this.map.addControl(new mapboxgl.NavigationControl());
    /*setTimeout(() => {
      new mapboxgl.Marker()
        .setLngLat(this.coordenadas.longitud, this.coordenadas.latitud)
        .addTo(this.map);
    }, 1000);*/

      new mapboxgl.Marker()
        .setLngLat([this.coordenadas.longitud,this.coordenadas.latitud])
        .addTo(this.map);
     
   
  },
  methods: {},
};
</script>
